<template>
  <div style="padding: 0 200px;">
    <div class="banner">
      <el-carousel height="400px" indicator-position="none">
        <el-carousel-item v-for="(item, index) in banners" :key="index">
          <img :src="item" width="1200" height="400" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="menu-p">
        <div class="menu-p-item" v-for="(item, index) in menuP" :key="index"
          :class="{ 'menu-p-item-check': currentP == index }" @click="checkMenu(index, item)">
          <div :class="{ 'item-text': currentP == index }">{{ item.name }}</div>
          <div v-if="index==currentP" class="underline"></div>
        </div>
      </div>
    </div>
    <div class="atypes">
      <div class="atype-item" v-for="(item, index) in atypes" :key="index"
        :class="{ 'atype-item-active': current == index }" @click="select(item, index)">
        {{ item.name }}
      </div>
    </div>

    <div class="content">
      <div class="item" v-for="(item, index) in list.rows" :key="index">
        <div>
          <!-- <el-image style="width: 100px; height: 100px;" :src="item.cover" fit="cover"></el-image> -->
          <div style="overflow: hidden; height: 120px; width: 200px; border-radius: 5px;">
            <img :src="item.cover" class="image" height="150" style="width: 100%;" />
          </div>
          <!-- <div class="day">{{ item.published_time?.slice(8, 10) }}</div>
          <div class="year">{{ item.published_time?.slice(0, 7) }}</div> -->
        </div>
        <div class="item_title" @click="detail(item)">
          <div>{{ item.title }}</div>
          <div class="content-item">
            {{ item.intro ? textFormat(item.intro, 150) : '无简介' }}
          </div>
        </div>
        <div style="margin-left: auto;">
          <div class="day">{{ item.published_time?.slice(8, 10) }}</div>
          <div class="year">{{ item.published_time?.slice(0, 7) }}</div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; padding: 10px 0">
      <el-pagination background :page-size="list.pageSize" :current-page="list.page" :total="list.total"
        @current-change="pageChange" layout="prev, pager, next, total" />
    </div>
  </div>
</template>

<script setup>
import conf from '@/conf'
import { ref, reactive, onMounted } from 'vue'
import axios from '@/libs/axios'
import { useRouter } from 'vue-router'
import { setVarStyle } from '@/utils/tools'

const list = reactive({
  type_id: 0,
  page: 1,
  pageSize: 10,
  rows: [],
  total: 0
})
const banners = ref([])
const current = ref(0)
const atypes = ref([])
const router = useRouter()

// 父级菜单
const menuP = ref([])
const currentP = ref(0)
const checkMenu = async (index, item) => {
  currentP.value = index
  // list.type_id = item.id
  parent_id.value = item.id
  await getAtypes()
}

const getAtypesParent = async () => {
  const {
    data
  } = await axios.get('/atypes/parentType', {
    params: {
      page: 1,
      pageSize: 99,
      type: 1
    }
  })
  menuP.value = data
  // 加载子分类
  parent_id.value = menuP.value[0].id
  await getAtypes()
  // console.log('type', atypes.value)
  // 初始化每个类型的分页信息
  // getArticles()
}

const dateFormat = (v) => new Date(v).toLocaleString()

const detail = (article) => {
  router.push({ name: 'Article', query: { id: article.id } })
}

const pageChange = (page) => {
  list.page = page
  getArticles()
}

const select = (atype, index) => {
  current.value = index
  list.type_id = atype.id
  list.page = 1
  getArticles()
}
const getBanners = async () => {
  const response = await axios.get('/banners/btype?btype=2')
  banners.value = response
}

const parent_id = ref(null)
const getAtypes = async () => {
  const { data, total } = await axios.get('/atypes', {
    params: { page: 1, pageSize: 99, parent_id: parent_id.value }
  })
  atypes.value = data
  list.type_id = atypes.value[0].id
  await getArticles()
}

const textFormat = (v, l) => (v.length > l ? v.slice(0, l) + '...' : v)

const getArticles = async () => {
  const { data, total } = await axios.get('/articles/gets', {
    params: {
      page: list.page,
      pageSize: list.pageSize,
      type_id: list.type_id
    }
  })
  list.rows = data
  console.log('list.rows :>> ', list.rows)
  console.log(data)
  list.total = total
}

onMounted(async () => {
  await getBanners()
  // await getAtypes()
  await getAtypesParent()
})
</script>

<style lang="less" scoped>
.banner {
  width: 1200px;
  height: 400px;
  position: relative;

  .menu-p {
    position: absolute;
    width: 1200px;
    height: 50px;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    background-image: url('../assets/bg1.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .menu-p-item {
      width: 50%;
      bottom: 50;
      // align-items: center;
      display: flex;
      justify-content: center;
      // flex-direction: column;
      font-size: 24px;
      font-weight: bold;
      color: rgb(128, 128, 128);
      // background-color: red;
    }

    .menu-p-item:hover {
      font-size: 26px;
      color: #fff;
      cursor: pointer;
    }

    .menu-p-item-check {
      color: #fff;
    }
    .underline {
      width: 50%;
      height: 5px;
      background-color: rgb(59, 163, 14);
      position: absolute;
      border-radius: 2px;
      bottom: 0;
    }
  }
}

.box-card {
  margin-bottom: 20px;
}

.atypes {
  display: flex;
  margin: 20px 0;
  justify-content: center;

  .atype-item {
    padding: 8px 15px;
    border: #ccc 1px solid;
    cursor: pointer;
    border-radius: 5px;
    margin: 10px;
  }

  .atype-item-active {
    color: #3aa20f;
    border: 1px solid #3aa20f;
    font-weight: 500;
  }

  .atype-item:hover {
    color: #3aa20f;
    border: 1px solid #3aa20f;
  }
}

.el-carousel__item h3 {
  display: flex;
  color: #475669;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.item_title {
  margin-left: 20px;
  margin-right: 20px;
  font-weight: bold;
  margin-top: 10px;
  cursor: pointer;
  text-align: justify;
}

.year {
  width: 100px;
  height: 30px;
  text-align: center;
  background-color: #f2f2f2;
  margin-top: 2px;
  padding: 2px 0;
  color: #666;
  line-height: 30px;
}

.day {
  margin-top: 14px;
  height: 60px;
  width: 100px;
  font-size: 30px;
  background-color: #f2f2f2;
  // background-color: #3aa20f;
  text-align: center;
  line-height: 60px;
}

.image {
  transition: all 0.2s linear;
}

.image:hover {
  transform: scale(1.2);
}

.content {
  display: flex;
  flex-direction: column;

  .item {
    display: flex;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: silver 1px solid;
    // background-color: #3aa20f;
  }

  .content-item {
    font-size: small;
    color: #999;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
</style>
